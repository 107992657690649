import { useMemo } from 'react';
import { useRouter } from 'next/router';

const useRedirectURL = () => {
  const router = useRouter();
  const currentPath = router.asPath;

  const links = useMemo(() => {
    let loginLink = '/user/login';
    let registerLink = '/user/register';

    const userPaths = [
      '/user/login',
      '/user/register',
      '/user/forgotPassword',
      '/user/setPassword',
      '/user/confirmotp',
    ];

    if (router.isReady) {
      if (!userPaths.includes(currentPath) && !router.query.redirect) {
        loginLink = `/user/login?redirect=${currentPath}`;
        registerLink = `/user/register?redirect=${currentPath}`;
      } else if (router.query.redirect) {
        loginLink = `/user/login?redirect=${router.query.redirect}`;
        registerLink = `/user/register?redirect=${router.query.redirect}`;
      }
      return { loginLink, registerLink };
    }
  }, [currentPath, router.isReady, router.query.redirect]);

  return {
    loginLink: links?.loginLink || '',
    registerLink: links?.registerLink || '',
  };
};

export default useRedirectURL;
