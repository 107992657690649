import { useRouter } from 'next/router';
import cn from 'classnames';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { DEFAULT_SUPER_CATEGORY } from '@ictlife/marketplace/constants';
import useSuperCategoryAndLocation from '@ictlife/marketplace/hooks/useSuperCategoryAndLocation';
import s from '../styles/topbar/MobileNav.module.scss';
import useRedirectURL from '../../../hooks/useRedirectURL';

export const BusinessLinks = ({ isBusinessOwner }) => {
  const router = useRouter();
  const { b_username: business_username, section } = router.query;

  return (
    <div className="mid-section">
      <div className="top-header">
        {business_username && (
          <div
            className={cn({
              link: true,
              active: router.asPath === `/business/${business_username}`,
            })}
          >
            <Link href={`/business/${business_username}`}>
              <a>Home</a>
            </Link>
          </div>
        )}
        {business_username && (
          <div
            className={cn({
              link: true,
              active:
                router.pathname === '/business/[b_username]/[section]' &&
                section === 'offerings',
            })}
          >
            <Link href={`/business/${business_username}/offerings`}>
              <a>My Events</a>
            </Link>
          </div>
        )}
        {business_username && isBusinessOwner && (
          <div
            className={cn({
              link: true,
              active:
                router.pathname === '/business/[b_username]/profile/[section]',
            })}
          >
            <Link href={`/business/${business_username}/profile/logo`}>
              <a>Organiser Settings</a>
            </Link>
          </div>
        )}

        {/* {isBusinessOwner && (
          <div
            className={cn({
              link: true,
              active: router.pathname === '/business/[b_username]/activity',
            })}
          >
            <Link href={`/business/${business_username}/activity`}>
              <a>Customer Activity</a>
            </Link>
          </div>
        )} */}
        {isBusinessOwner &&
          process.env.NEXT_PUBLIC_MERCHANT_TRANSACTIONS_URL &&
          false && (
            <div className="link">
              <Link
                href={`${process.env.NEXT_PUBLIC_MERCHANT_TRANSACTIONS_URL}/home`}
              >
                <a>Transactions</a>
              </Link>
            </div>
          )}
      </div>
    </div>
  );
};

export const BrandLinks = () => {
  const router = useRouter();
  return (
    <div className="mid-section">
      <div className="top-header">
        <div
          className={cn({
            link: true,
            active: router.asPath === '/',
            [s.link]: true,
          })}
        >
          <Link href="/">
            <a>Home</a>
          </Link>
        </div>
        <div
          className={cn({
            link: true,
            active: router.asPath === '/about',
            [s.link]: true,
          })}
        >
          <Link href="/about">
            <a>About Us</a>
          </Link>
        </div>
        <div
          className={cn({
            link: true,
            active: router.asPath === '/policy',
            [s.link]: true,
          })}
        >
          <Link href="/policy">
            <a>Privacy Policy</a>
          </Link>
        </div>
        <div
          className={cn({
            link: true,
            active: router.asPath === '/contact-us',
            [s.link]: true,
          })}
        >
          <Link href="/contact-us">
            <a>Contact Us</a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export const AuthenticationLinks = () => {
  const isMarket = process.env.NEXT_PUBLIC_SOURCE_PACKAGE === 'marketplace';
  const { loginLink, registerLink } = useRedirectURL();

  return (
    <>
      <Link
        href={
          process.env.NEXT_PUBLIC_SOURCE_PACKAGE === 'merchant'
            ? '/user/login'
            : loginLink
        }
      >
        <a className="custom-button nav-button outlined with-icon">
          <span className="material-icons icon">&#xea77;</span>
          <span>Login</span>
        </a>
      </Link>
      {!isMarket && (
        <Link
          href={
            process.env.NEXT_PUBLIC_SOURCE_PACKAGE === 'merchant'
              ? '/user/register'
              : registerLink
          }
        >
          <a className="custom-button nav-button mobile-hidden">
            {process.env.NEXT_PUBLIC_SOURCE_PACKAGE === 'merchant'
              ? "Get Started, It's FREE"
              : 'Sign Up'}
          </a>
        </Link>
      )}
    </>
  );
};

export const MarketLinks = () => {
  const router = useRouter();
  const page = router.pathname;
  const { selectedCategory, search } = router.query;

  const { superCategory, locationForURL } = useSuperCategoryAndLocation();

  const userInfoState = useSelector(store => store.userInfoState);
  const {
    user: { id: userId },
  } = userInfoState;

  useEffect(() => {
    const elm = document.querySelector('#content-section');

    const handleElementScroll = () => {
      const scNav = document.querySelector('#sc-search-area-wrapper');
      const offsetdifferences = elm?.scrollTop - scNav?.offsetTop;

      if (offsetdifferences < 0) {
        document.querySelector('#mp-mid-section').style.display = 'none';
      } else {
        document.querySelector('#mp-mid-section').style.display = 'block';
      }
    };

    handleElementScroll();

    elm?.addEventListener('scroll', () => {
      handleElementScroll();
    });

    return () => {
      elm && elm.removeEventListener('scroll', handleElementScroll);
    };
  }, []);

  const updatedQueryParams = useMemo(() => {
    const params = {
      ...(search && { search }),
    };
    return params;
  }, [search]);

  return (
    <div className="mid-section" id="mp-mid-section">
      <div className="top-header">
        {userId && false && (
          <div
            className={cn('link', [s.link], {
              active: page.includes('/feed/'),
            })}
          >
            <Link
              href={{
                pathname: '/feed/posts',
              }}
            >
              <a>My Feed</a>
            </Link>
          </div>
        )}

        <div
          className={cn('link', [s.link], {
            active: superCategory === 'events',
          })}
        >
          <Link
            href={{
              pathname: '/[superCategory]/category/[selectedCategory]',
              query: {
                ...updatedQueryParams,
                superCategory: `events${locationForURL}`,
              },
            }}
          >
            <a>Events</a>
          </Link>
        </div>

        <div
          className={cn('link', [s.link], {
            active:
              superCategory === 'food' ||
              (page === '/' && DEFAULT_SUPER_CATEGORY === 'food'),
          })}
        >
          <Link
            href={{
              pathname: '/[superCategory]',
              query: {
                ...updatedQueryParams,
                superCategory: `food${locationForURL}`,
                section: 'businesses',
              },
            }}
          >
            <a>Food</a>
          </Link>
        </div>
        <div
          className={cn('link', [s.link], {
            active:
              superCategory === 'services' ||
              (page === '/' && DEFAULT_SUPER_CATEGORY === 'services'),
          })}
        >
          <Link
            href={{
              pathname: '/[superCategory]',
              query: {
                ...updatedQueryParams,
                superCategory: `services${locationForURL}`,
                section: 'businesses',
              },
            }}
          >
            <a>Services</a>
          </Link>
        </div>
        <div
          className={cn('link', [s.link], {
            active:
              superCategory === 'shopping' ||
              (page === '/' && DEFAULT_SUPER_CATEGORY === 'shopping'),
          })}
        >
          <Link
            href={{
              pathname: '/[superCategory]',
              query: {
                ...updatedQueryParams,
                superCategory: `shopping${locationForURL}`,
                section: 'businesses',
              },
            }}
          >
            <a>Products</a>
          </Link>
        </div>
        <div
          className={cn('link', [s.link], {
            active:
              superCategory === 'things-to-do' &&
              selectedCategory !== 'events' &&
              page !== '/',
          })}
        >
          <Link
            href={{
              pathname: '/[superCategory]',
              query: {
                ...updatedQueryParams,
                superCategory: `things-to-do${locationForURL}`,
              },
            }}
          >
            <a>Things To Do</a>
          </Link>
        </div>
        <div
          className={cn('link', [s.link], {
            active:
              superCategory === 'places-to-stay' ||
              (page === '/' && DEFAULT_SUPER_CATEGORY === 'places-to-stay'),
          })}
        >
          <Link
            href={{
              pathname: '/[superCategory]',
              query: {
                ...updatedQueryParams,
                superCategory: `places-to-stay${locationForURL}`,
                section: 'businesses',
              },
            }}
          >
            <a>Places To Stay</a>
          </Link>
        </div>
      </div>
    </div>
  );
};
